.footer {
    background-color: var(--container-color);
    border-top: 1px solid rgba(0, 0, 0, .1);
}
.footer__container { 
    padding: 2rem 0 6rem;
}
.footer__title, 
.footer__link {
    color: var(--title-color);
}
.footer__title {
    text-align: center;
    margin-bottom: var(--mn-2);

}
.footer__link:hover {
    color: var(--title-color-dark);
}

.footer__link:hover {
    color: var(--title-color-dark);

}
.footer__list {
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    margin-bottom: var(--mb-2);
}
.footer__social {
    display: flex;
    justify-content: center;
    column-gap:1.25rem
}
.footer__social-link {
    background-color: var(--container-color);
    color:var(--title-color);
    font-size: 2rem;
    display: inline-flex;
}
.footer__social-link:hover{
    color: rgba(0, 0, 0, .5);
}

.footer__copy {
    display: block;
    margin-top: 3.5rem;
    color: var(--title-color);
    text-align: center;
    font-size: var(--smaller-font-size);
}