.scrollup {
    position: fixed;
    right: 1.5rem;
    bottom: -20%;
    background-color: var(--title-color);
    opacity: 0.8;
    padding: 0.3rem 0.5rem;
    border-radius: .4rem;
    z-index: var(--z-tooltip);
    transition: .4s;
    display: flex;
    align-items: center;
      
}
.show-scroll{
    bottom: 1rem;
}
.scrollup__icon {
    font-size: 1.5rem;
    color: var(--container-color);
}
.scrollup:hover {
    background-color: var(--title-color-dark);
}

@media screen and (max-width: 992px) {
 .scrollup{
    right: 1.5rem;
    padding: .25rem 0.4rem;
 }
 .scrollup__icon {
    font-size: 1.25rem;
}
  
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px){
    .scrollup{
        bottom: 6rem;
     }

  }
  @media screen and (max-width: 576px) {
  }
  /* For small devices */
  @media screen and (max-width: 350px) {
    .container{
      margin-inline: var(--mb-1);
    }
  }