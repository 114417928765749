.work__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap:0.75rem;
    margin-bottom: var(--mb-2);

}
.work__pagination{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-block: 6rem 2rem;
}
.work__pagination button{
    padding: .5rem 2rem;
    border-radius: 8px;
    width: 120px;
    display: flex;
    justify-content: center;
    background-color: var(--title-color);
    color: white;
    transition: all .2s ease-in-out;
}
.work__pagination button:hover{
    opacity: .8;
    transform: scale(1.05);
}
.work__item {
    cursor: pointer;
    color: var(--title-color);
    padding: 0.25rem 0.75rem;
    font-weight: var(--font-medium);
    border-radius: .5rem;
    transition: all .2s ease-in-out;
}
.work__item:hover,
.active-work {
    background-color: var(--title-color);
    color: var(--container-color);
}
.work__container {
    width: 100%;
    grid-template-columns: repeat(2,1fr);
    gap: 3rem;
    justify-content: center;
}
.work__card {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 1rem;
    position: relative;
    width: 100%;
    overflow: hidden;
    transition: all .5s ease-in-out;

}
.work_img-cont{
    width: 100%;
    height: 75%;
    padding-top: 1.25rem;
    padding-inline: 1.25rem;
    z-index: 1;
    transition: all .5s ease-in-out;
}
.work_img-cont--hover {
    height: 100%;
    padding: 0;
}
.work__img {
    width: 100%;
    border-radius: 1rem;
    border: solid 1px rgba(0, 0, 0, 0.1) ;
    margin-bottom: var(--mb-1);
    object-fit: cover;
    /* border: solid 1px black; */
    height: 100%;
 

}
.work__title{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    /* margin-bottom: var(--mb-0-5); */
}
.work__button {
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: flex;
    align-items: center;
    column-gap:0.25rem;
    width: max-content;

}
.work__button:hover{
    transform: scale(1.1);
    text-shadow: -4px 0px 6px rgba(255,255,255,0.6);
    text-decoration: underline;
}
.work__button-icon {
    font-size: 1rem;
    transition: .4s;
}
.work__button:hover .work__button-icon {
    transition: translateX(.25rem);
}
.work__card:hover {
 padding: 0;
}
.work__lower-cont{
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    transition: all .2s ease-in-out;
    padding: 1.25rem;
    padding-bottom: 0;
    z-index: 3;
 
}
.work__lower-cont--hover{
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    bottom: 100%;
    height: 100%;
}

.work__img--hover{
    height: 100%;
    width: 100%;
    border: solid 1px transparent;
 
}
.work__title--hover,.work__button--hover{
    color: white;

   
}
@media screen and (min-width: 1080px) {
    .work__container {
        grid-template-columns: repeat(3,1fr);
        gap: 1rem;
    
    }
    .work__lower-cont {
        bottom: 0.5rem;
    }
    .work__lower-cont--hover{
        bottom: 100%;
   
    }
    
}
@media screen and (max-width: 992px) {
  
    .work__container {
        gap: 1.25rem;
        width: 90%;
        
    }
    .work__card {
        padding: 1rem;
    }
    .work__img {
        margin-bottom: 0.75rem;
    }
    .work__title {
        margin-bottom: 0.25rem;
    }
}

@media screen and (max-width: 716px) {
    .work__container {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 560px) {
   
}

@media screen and (max-width: 350px) {
  

}