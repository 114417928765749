.services__container {
    grid-template-columns: repeat(3, 270px);
    justify-content: center;
    column-gap:1.8rem;

}
.services__content {
    position : relative;
    border: 1px solid rgba(0, 0, 0, .1);
    background-color: var(--container-color);
    padding: 6rem 1rem 2rem 2.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
      
}
.services__icon {
   display: block;
   font-size: 2rem;
   color: var(--title-color);
   margin-bottom: var(--mb-1);  
}
.services__title {
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-1);
    font-weight: var(--font-medium);
}
.services__button{
    color: var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    column-gap: .25rem;
    cursor: pointer;
    transition: .3s;
    margin-bottom: .1rem;
    position: relative;
    width: max-content;
}
.service__button-icon {
    font-size: 1rem;
    transition: .3s;
}
.services__button:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 5;
    background-color: var(--title-color);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  .services__button:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
.services__button:hover .service__button-icon {
    transform: translateX(.25rem);
}
 
.services__modal {
    display: none;
}
.services__modal {
    position: fixed;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5); 
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    padding: 0 1rem;
    visibility: hidden;
    transition: .3s;
}
.services__modal-content{
    width: 500px;
    position: relative;
    background-color: var(--container-color);
    padding: 3rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
}
.servics__modal-close{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size:1.5rem ;
    cursor: pointer;
}
.services__modal-title,
.services__modal-description {
    text-align: center;
    width: 90%;
    margin-inline: auto;
}
.services__modal-title{
    font-size: var(--h3-font-size);
    padding: 0 3.5rem;
    margin-bottom: var(--mb-1);
}
.services__modal-description {
    margin-bottom: var(--mb-2);
}
.services__icon{
    font-size: 1.1rem;
    color:var(--title-color);
}
.services__modal-service{
    row-gap: .75rem;
    display: flex;

}
.services__modal-info{
    margin-left: .5rem;
    font-size: var(--small-font-size);
}
.services__modal-info{
   margin-block:0 ;
}
.grids {
     gap: 0rem;
}
.active-modal {
    opacity: 1;
    visibility: visible;
}
 
@media screen and (max-width: 992px) {
   .services__container {
    grid-template-columns: repeat(3, 218px);
   }
 }
 
 /* For medium devices */
 @media screen and (max-width: 768px){
    .services__container {
        grid-template-columns: repeat(2, 1fr);
       }
       .services__content {
        padding: 3.5rem .5rem .25rem 1.5rem;
       }
    
 }
 @media screen and (max-width: 576px) {
      .services__modal-content{
        padding: 3rem 1rem 2.5rem;
      }
      .services__modal-description{
        padding: 0;
      }
 }
 /* For small devices */
 @media screen and (max-width: 350px) {
    .services__container {
        grid-template-columns: max-content;
    }
    .services__content {
        padding-right: 6rem;
    }
 }
 