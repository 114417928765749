.qualification__container {
    margin-inline:auto ;
    max-width: 768px;
}
.qualification__tabs {
    display: flex;
    justify-content: center;
    margin-bottom:var(--mb-2);

}
.qualification__button {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    color: var(--text-color);
    margin: 0 var(--mb-1);
    cursor: pointer;
}
.qualification__button:hover {
    color:var(--title-color-dark);
}

.qualification__icon {
    font-size: 1.2rem;
    margin-right: var(--mb-0-25);
}
.qualification__active {
    color:var(--title-color-dark);
} 
.underline {
    border-bottom: 2px solid black; 
    padding-bottom: 1px;
} 
.qualification__sections {
    display: grid;
    grid-template-columns: .5fr;
    justify-content: center;
}
.qualification__content {
    display: none;
}
.qualification__content-active{
    display: block;
}
.qualification__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.qualification__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}
.qualification__subtitle {
    display: inline-block;
    font-size: var(--small-font-size);
    margin-bottom: .5rem;
    color: var(--text-color);
}

.qualification__calender {
    display: flex;
    align-items: center;
    font-size: var(--small-font-size);  
    color: var(--text-color);
    margin-bottom: .5rem;
}
.qualification__rounder {
    display: inline-block;
    width: 19px;
    height: 19px;
    background-color: var(--text-color);
    border-radius: 50%;

}
.qualification__line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--text-color);
    transform: translate(9px, -7px);
}

@media screen and (max-width: 992px) {
  
    .qualification__container {
        margin-inline: auto;
    }
  }
  
  @media screen and (max-width: 768px){
    .qualification__data {
     
        grid-template-columns: 150px max-content 150px;
       }
    .qualification__container {
        margin-inline: var(--mb-1-5);
    }

  }
  @media screen and (max-width: 650px) {
    .qualification__sections{
        grid-template-columns: initial;
    }
    .qualification__button {
        margin: 0 var(--mb-0-75);
    }
  }

  @media screen and (max-width: 425px) {
    .qualification__data {
       gap: 0.5rem;
       }
   
  }