.skills__container {
    grid-template-columns: repeat(2,350px);
    justify-content: space-evenly;
}
.skill__content {
    background-color: var(--container-color);
    border:1px solid rgba(0, 0, 0, .1);
    padding: 2rem 3rem;
    border-radius: 1.25rem;
}
.skills__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-1-5);
}
.skills__box {
    display: flex;
    justify-content: center;
    column-gap: 2.5rem;

}
.skills__group {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(2,1fr);
    row-gap: 1.5rem;
    column-gap: 2rem;
}
.skills__data { 
    display: flex;
    column-gap: .5rem;
    
}
.skills__icon{
    font-size: 1rem;
    color: var(--title-color);
    margin-top: 0.2rem;
}
.skills__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}
.skills__level {
    font-size: var(--tiny-font-size);
}


@media screen and (max-width: 992px) {
     .skills__container { 
        row-gap: 2rem;  
     }
  }
  
  /* For medium devices */
  @media screen and (max-width: 742px){
    .skills__container {
        grid-template-columns: max-content;
       
        
     }
  
   
  }
  @media screen and (max-width: 576px) {
    .skills__container {
        grid-template-columns: 1fr;
    }
    .skills__content {
       padding: 1.5rem;
    }
  }
  /* For small devices */
  @media screen and (max-width: 350px) {
   
  }
  