.header {
    width: 100%;
    height: min-content;
    position: fixed;
    top:0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
}
.nav{
    height:var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}
.nav__logo,
.nav__toggle{
    color: var(--title-color);
    font-weight: var(--font-medium);
}
.nav__list { 
    display: flex;
    column-gap: 2rem;
}
.nav__link{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--small-font-size);
    color: var(--title-color);
    font-weight: var(--font-medium);
    transition: .3s;
     
}
.nav__item a:visited{
    text-decoration: none;
    color: var(--title-color);
}

.nav__icon,
.nav__close,
.nav__toggle{
    display: none;
}

.active-link,
.nav__link:hover {
    color: var(--title-color-dark);
    transform: scale(1.1);
}
.scroll-header{

    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}
@media screen and (max-width: 768px){
    /* not working  */
    .header {
        top: initial;
        bottom: 0;
        position: absolute;
       
    }
   .nav{
    position: fixed;
    bottom: 0;
    width: -webkit-fill-available;
    background: var(--body-color);
   }
    .nav-menu{
        height: fit-content;
        position: fixed;
        bottom: 0;
        left:0;
        width: 100%;
        background-color: var(--body-color);
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 1.5rem 1.5rem 0 0 ;
        transition: .3s;
        /* width: auto; to compare if rounded borders are better*/
        margin-left: 0;
        z-index: 2;
        transform: translateY(100%);
    }
      .show-menu{
        transform: translateY(0%);
      }

    .nav__list{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }
    .nav__icon{
        font-size:1rem ;
    }
    .nav__logo,.nav__toggle {
        display: block;
        z-index: 1;
    }
    .nav__toggle{
        cursor: pointer;
    }
    .nav__close{
           position: absolute;
           right: 1.3rem;
           bottom: .7rem;
           font-size: 1.2rem;
           cursor: pointer;
           color: var(--title-color);

    }
  
    .nav__icon,
    .nav__close{
        display: block;
    }
  
}
@media screen and (max-width: 350px) {
    .nav__menu{
        padding: 2rem 0.25rem 4rem;
    }
    .nav__list{
        column-gap: 0;
    }
}
