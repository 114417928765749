.hand {
    color: #6A452C;
    width: 32px;
    height: 32px;
    margin-left: 0.4rem;
    opacity: 0;
    animation: svg 1s ease-in-out 1s forwards;
}

.home__content {

    grid-template-columns: 116px repeat(2, 1fr);
    padding-top: 10.5rem;
    column-gap: 2rem;
    align-items: center;
}
.char {
    transform: translateY(115px);
    transition: transform .5s;
}

.home__img {

    background: url('../../images/profilePic.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    animation: profile__animate 8s ease-in-out infinite .2s;
}

.home__social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.home_social-icon {
    font-size: 1.25rem;
    color: var(--title-color);
}

.home_social-icon:hover {
    color: var(--title-color-dark);
}

.home__title {
    font-size: 2.7rem;
    margin-bottom: var(--mb-0-25);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    /* display: flex;
    align-items: center; */
}

.home__subtitle {
    position: relative;
    font-size: var(--h3-font-size);
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
    width: max-content;
}

.home__subtitle::before {
    content: "";
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
    top: 50%;
    right: -60%;
}

.home__description {
    max-width: 400px;
    margin-bottom: var(--mb-3);
    font-size: .9rem;
}

.button-icon {
    margin-left: 0.5rem;
    font-size: .8rem;
}

.home__scroll {
    margin-left: 9.25rem;
    display: flex;
    align-items: center;
}

.scroll__container {
    width: 12px;
    height: 24px;
    border: solid 1px black;
    border-radius: 8px;
    padding: 2px 4px;
}

.scroll__toggle {
    width: 2.5px;
    height: 8px;
    color: black;
    background: black;
    border-radius: 4px;
    margin: 0;
    animation: scroll 1.5s ease-in-out infinite;
}

.home__scroll-name {
    color: var(--text-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 8px;
    font-size: .9rem;
}
.opacity {
    opacity: 0;
}
@keyframes svg { 
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
        transform: rotate(-30deg);
    }
    80% {
      
        transform: rotate(30deg);
    }
    100% {  opacity: 1; 
    
    }
}


@keyframes profile__animate {
    0% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
    }

    100% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }

}

@keyframes scroll {
    0% {
        transform: translateY(0%);
    }

    30% {
        transform: translateY(50%);
    }
}


@media screen and (max-width: 992px) {
    .home__content {
        grid-template-columns: 100px repeat(2,1fr);
        column-gap: 1.25rem;
    }
    .hand {
        width: 24px;
        height: 24px;
    }
    .home__subtitle{
        margin-bottom:var(--mb-1)
    }
    .home__subtitle::before {
        width: 48px;
        right: -50%;
    }
    .home__description {
        max-width: initial;
        margin-bottom:var(--mb-2-5); 
    }
    .home__img { 
        width: 250px;
        height: 250px;
        box-shadow: inset 0 0 0 8px rgb(255 255 255 / 30%);
    }
    .home__scroll {
        margin-left: 7.5rem;
    }
      
    
}

@media screen and (max-width: 768px) {
       .home__content {
        grid-template-columns: 0.5fr 3fr;
        padding-top: 8rem;
       }
       .home__img {
        order: initial;
        justify-self: initial;
       }
       .home__data {
        grid-column: 2/3;
       }
       .home__img { 
        width: 200px;
        height: 200px;
        box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
    }
    .home__scroll {
        display: none;
    }
}

@media screen and (max-width: 490px) {
    .home__data {
        grid-column: 1/3;
       }
}

@media screen and (max-width: 350px) {
  
    .home__img {
        width: 180px;
        height: 180px;
    }
    .home__hand {
        width: 18px;
        height: 18px;
    }
}